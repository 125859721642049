import axios from "axios";
import { Message } from 'element-ui';
import router from "@/router";
import store from "@/store";
import config from "@/config";

/************************************** 需要鉴权的请求 **************************************/
const t = axios.create({
  baseURL: config.server,
  timeout: 30000
})

let isErroring = false;

t.interceptors.request.use(
  request => {
    //如果没有token，则返回登录页面
    if(!store.state.token){
      router.push({ path: config.loginRoute})
    }
    request.headers.Authorization = 'bearer ' + store.state.token;
    //若是分页接口，则将table的loading加载起来
    if(request.url.indexOf('/page') > -1){
      store.state.loading = true;
    }
    return request;
  },
  error => {
    return Message.error(error);
  }
);

t.interceptors.response.use(
  response => {
    //如果登录信息过期
    if(!response.data.success && response.data.code == 401){
      router.push({ path: config.loginRoute})
      return null;
    }
    //若是分页接口，就将table的loading停止加载
    if(response.config.url.indexOf('/page') > -1){
      store.state.loading = false;
    }
    return response.data;
  },
  error => {
    if(!isErroring){
      isErroring = true;
      setTimeout(() => {
        isErroring = false;
        return Message.error("抱歉，服务已中断，请稍候再试。");
      }, 1000);
    }
    Promise.reject(error);
  }
);

/************************************** 不需要鉴权的请求 **************************************/
const nt = axios.create({
  baseURL: config.server,
  timeout: 30000
})

nt.interceptors.request.use(
  request => {
    //登录时携带上验证码key
    if(localStorage.getItem("validCodeKey") && request.url == "/oauth/adminAuthorize"){
        request.headers['valid-code-key'] = localStorage.getItem("validCodeKey");
        localStorage.removeItem("validCodeKey");
    }
    return request;
  },
  error => {
    return Message.error(error);
  }
);

nt.interceptors.response.use(
  response => {
    //获取验证码时保存验证码key至缓存
    if(response.config.url == response.config.baseURL + "/oauth/getValidCode"){
      localStorage.setItem("validCodeKey", response.headers['valid-code-key']);
    }
    return response.data;
  },
  error => {
    if(!isErroring){
      isErroring = true;
      setTimeout(() => {
        isErroring = false;
        return Message.error("抱歉，服务已中断，请稍候再试。");
      }, 1000);
    }
    Promise.reject(error);
  }
);
 
export {t, nt}