<template>
    <div class="banner" style="text-align:center;">
		<div class="content" style=" ">
			<p style="padding-top:35%;color: #CC6600; font-size:25px; font:bold;">订 购 成 功</p>
            <p style="padding-top:30%; padding-left:0%;font-size:14px; text-align: center;">
				<font color="#CC6600">恭喜亲，已成功提交业务申请</font>
			</p>
			<p style="padding-top:5%; padding-left:25%;font-size:14px; text-align: left;">
				<font color="#CC6600"></font>
			</p>
            <p style="padding-top:2%; padding-left:0%;font-size:14px; text-align: center;">
				<font color="#CC6600">业务订购生效将以短信告知</font>
			</p>
			
			
			<p style="padding-top:8%; padding-left:8%;font-size:14px; text-align: left;"></p>
			<img src="../../assets/img/suc_sub.png" style=" width:60%; height:60%; padding-top:5%;" @click="imgclick">
      <img src="../../assets/img/lqqy_sub.png" style=" width:60%; height:60%; padding-top:5%;" @click="lqqyclick">
			<p style="padding-top:10%; padding-left:8%;font-size:14px; text-align: left;"></p>
            
            
        </div>
		<div class="footer" style="">
			<div style="height:400px;"></div>
		</div>
		
	</div>
</template>
<script>

export default {
  name: "Success",
  data() {
    return {}
  },
  methods: {
    imgclick() {
      window.location.href =
        "https://m.10155.com/h5/mactivity/index.html#/openmp?path=subpages%2FshakeList%2FshakeList&channelId=3000016027";
    },
    lqqyclick() {
      window.location.href =
        "https://6gmm.com/wx/music/xzhdyt.html";
    },
  }
}
</script>

<style scoped>
@import "../../assets/css/womusic.css";
.content {
    background-image:url(../../assets/img/suc_bg.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    width:100%;
    height:100%;
    text-align:center
}
.footer {
    background-image:url(../../assets/img/suc_bg1.png);
    background-size:cover;
    background-repeat:repeat-y;
    width:100%;
    height:100%;
    margin-top:-10px;
}
</style>