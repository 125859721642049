<template>
  <div id="app">
    <div class="video-container-new" id="mainElement" style="">
      
      <img src="@/assets/img/header_new.png" class="top_bg" alt="" />
      <div class="rule_btn" @click="ruleClick" style="background: none;"></div>
      <div class="login-content" v-if="!isLogin">
        <!--<div class="login_msg">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAAXNSR0IArs4c6QAAAeBJREFUaEPlmmuSwyAIgOVm25M1PVnbk7lDRrMmRUXRBLrOdPpHI588RBTcRc17/+Ocuzvn8P+FPwB4SMUB6Qd6xnvvnwHkOHyRQp0KdNAKuRYAIJJJNLhFOwEGNVNrNwBAE+xqpwB579FXFqaEIrObDtQIg8x6gQrOX1KWPqAGf6HAdAF1mNjQ0D3UhwbA6PGhTn/RZ3KczZIZrmO363xI6Pw5TjnQhFVuVMquexUo+ComtdgeaWYBk1Z5GlAm8GzpEgK1pCUSQbljixrKyLuNQSDPnemkfj1ALmbpFoHQd6isfV0Ic0BoJRmrMg1E+j2anUkNFbVkLSjEwJRJtRbLGqLM7uuAXt+mIdNA5H5kWUP/Asi0yVFRzjQQVR83HbYpoJvJoFA4lJoFIq9jzCanmfwTL8zsaahobgBrlMvdpp104v6YpnYEp+RdtYNfsghE7T9/VZ/CYUmrhj6A0mvMrVh/KN5xYWKxj9uf049TaIw36Hh1+d4VGjkzlPpM8MEqUEmeIdcpg4uV1wMFPxxVgdUBNBBKD1CASp+89LioLqCkzNRrgjqBBCaoF6gTSjdQ4lecdz7YXT9QpXx7DBz6Hy+lEtc2YTPPy5hQInNbjw89G8WoMUFb8XO7JLN3jl/lQHuWRoSPcAAAAABJRU5ErkJggg=="
              class="horn"
              alt=""
            />
            <div class="msg_text">
              <div
                class="van-swipe my-swipe scroll-banner"
                style="height: 0.3rem"
              >
                <div
                  class="van-swipe__track van-swipe__track--vertical"
                  :style="{
                    'transition-duration': '500ms',
                    height: `130px`,
                    transform: `translateY(${scrollOffset}rem)`,
                  }"
                >
                  <div
                    class="van-swipe-item"
                    style="height: 0.3rem"
                    v-for="(item, index) in historyList"
                  >
                    <div class="banner-item">
                      <span class="time_font">{{ item.time }}</span>
                      <div class="right_text">
                        {{ item.name }}
                        <span class="bold_font">{{ item.phone }}</span>
                        {{ item.desc }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        <!----><!----><!---->
        <div>

          <div class="input_box">

            <el-input class="login_input" id="login_input" maxlength="11" v-model="phoneNumber"
              placeholder="请输入联通手机号码">

            </el-input>
            <el-image :src="require('@/assets/img/input_top.png')" class="input_top"></el-image>
          </div>
          <!--<div class="login_btn_new" @click="order"></div>-->
          <div class="login_btn_new" @click="search"></div>
          <div class="vip_login" @click="openCode">
            <span style="text-decoration: underline;">我是会员，登录免流畅看精品短剧 &gt;&gt;</span>
          </div>
          <div class="raido">
            <div :class="!hasChecked ? 'inputs-new' : 'inputs-new checked-new'" @click="raidoClick">
              <div class="haschecked" v-if="hasChecked"></div>
            </div>
            <div class="tips-new">
              <p>我已阅读并同意《活动说明》和《信息授权保护声明》等协议</p>
              <p>温馨提示：</p>
              <p>当月不退订次月自动续订，产品资费25元/月</p>
              <p>产品提供方:中文在线集团股份有限公司</p>
              <p>服务提供方:联通沃音乐文化有限公司</p>
              <p>客服热线:4000410155</p>
            </div>

          </div>
          <!---->
        </div>
      </div>
      <div class="login-content is-login" v-else>
        <div class="logined">
          <p>尊敬的{{ phoneNumber }}用户 <button class="log-out" @click="logout"> 退出 </button></p>
          <p v-if="hasOrdered">您已开通会员，</p>
          <p v-if="hasOrdered">快去领取本月权益吧～</p>
          <p v-if="!hasOrdered">您还未订购此项业务</p>
        </div>
      </div>
      <div class="list-container">
        <div class="list_title">

        </div>
        <div class="list-wrapper">
          <!--<div
              class="swiper swiper-initialized swiper-horizontal swiper-pointer-events tabSwiper swiper-backface-hidden"
              slidwidth="2rem"
            >
              <div
                class="swiper-wrapper"
                style="transform: translate3d(0px, 0px, 0px)"
              >
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">旷世逍遥神医</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">重回1998</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">女总裁的嚣张保镖</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">战神令</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">婚礼上我换新郎</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">总裁的天价前妻</div>
                </div>
              </div>
            </div>-->
          <div class="video-list">
            <div class="swiper-container" style="width: 6.4rem;">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in videoList">
                  <div class="list-item">
                    <div class="video-box" @click="videoClick(item.videoImg, item.videoSrc, item.videoDesc)">
                      <img alt="img" class="video-img" :src="item.videoImg" :data-src="item.videoImg" lazy="loaded" />

                    </div>
                    <div class="video_desc">{{ item.videoDesc }}</div>
                  </div>

                </div>
              </div>


              <!-- 如果需要滚动条 -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
            <div class="tips">
              <p>搜索<span>“联通视频彩铃”</span>微信小程序,在首页视频彩号专区-</p>
              <p>短剧频道不限次观看频道内短剧内容</p>
            </div>

          </div>
        </div>
        <el-image :src="require('@/assets/img/bottom.png')"></el-image>
      </div>

      <el-image :src="require('@/assets/img/tq2-new2.png')" style="margin-bottom: .5rem;" @click="movieClick"></el-image>
      <div class="footer-new">
        <div class="footer_title">

        </div>
        <el-tabs v-model="activeName" type="" style="width: 90%;margin-left: 5%;">
          <el-tab-pane name="super">
            <el-image slot="label" v-if="activeName == 'super'" :src="require('@/assets/img/czqy1.png')"></el-image>
            <el-image slot="label" v-else :src="require('@/assets/img/czqy2.png')"></el-image>
            <el-row style="padding-left: 10px;padding-right: 10px;">
              <el-col v-for="(item, index) in czqy" :key="index" :span="8"
                style="padding: 5px;">
                <el-card shadow="always" class="no-padding hidden-scorll qy-card" :ref="'xlqyCard' + index">
                  <el-row style="height: 45%;">
                    <el-image :src="item.imgSrc" style="height: 90%;"></el-image>
                  </el-row>
                  <el-row style="height: 15%;white-space: pre-line;">
                    {{ item.desc }}
                  </el-row>
                  <el-divider></el-divider>
                  <el-row style="height: 15%;">
                    价值：{{ item.price }}
                  </el-row>
                  <el-row style="height: 0.5rem;">
                    <el-button class="button-lq" type="" @click="lqqy(item)">点击查看</el-button>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="limited">
            <el-image v-if="activeName == 'limited'" slot="label" :src="require('@/assets/img/xlqy2.png')"></el-image>
            <el-image v-else slot="label" :src="require('@/assets/img/xlqy1.png')"></el-image>
            <div class="xlqy-text">限量权益每天8点更新，数量有限，领完即止</div>
            <el-row style="padding-left: 10px;padding-right: 10px;">
              <el-col v-for="(item, index) in xlqy" :key="index" :span="8"
                style="padding: 5px;">
                <el-card shadow="always" class="no-padding hidden-scorll qy-card" :ref="'xlqyCard' + index">
                  <el-row style="height: 45%;">
                    <el-image :src="item.imgSrc" style="height: 90%;"></el-image>
                  </el-row>
                  <el-row style="height: 15%;white-space: pre-line;">
                    {{ item.desc }}
                  </el-row>
                  <el-divider></el-divider>
                  <el-row style="height: 15%;">
                    价值：{{ item.price }}
                  </el-row>
                  <el-row style="height: 0.5rem;">
                    <el-button class="button-lq" type="" @click="lqqy(item)">点击查看</el-button>
                  </el-row>
                </el-card>
              </el-col>
            </el-row>
          </el-tab-pane>
          
        </el-tabs>
      </div>
      <el-image :src="require('@/assets/img/bottom.png')"></el-image>

      <div class="zndh-context-new">
        <div class="zndh_context_title">

        </div>
        <div class="zndh-box">
          <img @click="clickTop" src="@/assets/img/top.png" style="width: 100%;" alt="" />
        </div>
      </div>
      <el-image :src="require('@/assets/img/bottom.png')"></el-image>
      <div class="footer-context-new">
        <div class="footer_context_title">

        </div>
        <div class="text-box">
          <p class="text">
            1、产品名称：视频彩铃应用型产品尊享版25元包月（微剧版）<br/>
            2、产品资费：25元/月<br/>
            3、活动时间：2024年05月30日-2024年12月31日<br/>
            4、活动对象：中国联通4G/5G手机用户<br/>
            5、计费规则：<br/>
            （1）订购视频彩铃应用型产品尊享版25元包月（微剧版）-当月生效业务，扣费后立即生效。当月如不退订，次月1日系统自动扣除包月费用,每月自动续订,从话费中扣取费用。<br/>
            （2）订购视频彩铃应用型产品尊享版25元包月（微剧版）-次月生效业务，扣费后次月1日起生效。业务生效起，如未退订，每月1日系统自动扣除包月费用,每月自动续订,从话费中扣取费用。<br/>
            6、活动内容：成为“视频彩铃应用型产品尊享版25元包月（微剧版）会员”可享有订购前12个月每月权益N选1、短剧不限次免流观看和短剧彩铃订阅设置。<br/>
            【热门权益N选1】<br/>
            （1）订购成功后，前12个月每月可获得热门互联网每月选1款的权益，逾期未领取视作自动放弃使用机会，不予补发。<br/>
            （2）可通过页面点击跳转领取页面领取，领取后，VIP月卡将充值到您开通的手机号码中，使用该手机号到指定APP上登录即享VIP特权。如领取权益失败，请检查APP账号是否有注册和绑定相应的手机号码，请确认注册和绑定相应的手机号码后，再进行领取使用。<br/>
            （3）领取规则：超值权益：当月领取权益，若当月未领取，逾期无效；限量权益：每天8点更新，当日领完即止。<br/>
            （4）5G宽视界钻享会员+定向100G流量<br/>
            1、凡成为中国联通“视频彩铃应用型产品尊享版25元包月(微剧版)”会员，点击barmer跳转至5G宽视界H5(http://actiyity1.jsbc0505,com/#/womusicew2)，或下载5G宽视界APP、登录5G宽视界微信小程序，登录订购会员号码，点击首页-放映厅，每月可完整观看放映厅频道内影视内容;<br/>
            2、观看过程中，每月可享定向免流服务，页面中会有免流量播放提示。<br/>
            注:详细免流规则请见5G宽视界APP、H5、微信小程序我的-关于其他-关于免流。
            <br/>
            【短剧免流观看&彩铃设置】<br/>
            在产品订购有效期间，您可以搜索“联通视频彩铃”微信小程序，在发现一视彩号专区一爆款短剧，不限次观看频道内短剧内容，或订阅精选短剧彩铃为主叫彩铃或彩振一体。<br/>
            7、权益内容及视频彩铃等会员服务将根据资源合作情况不定期调整，请以页面展示为准。<br/>
            8、可在本页面设置短剧彩铃和免流观看短剧内容。<br/>
            9、每个手机号仅限参与一次，用户退订后再订购，则无法继续享有前12个月领取权益资格。<br/>
            10、退订说明:<br/>
            (1)人工客服退订:用户可拨打联通当地客服热线4000410155或10010，咨询相关业务退订操作。<br/>
            (2)线上自助退订:微信登录联通视频彩铃小程序，通过右下方“我的”>“我的彩铃会员”，找到对应已开通的会员产品，点击“退订”，即可实现包月产品退订。<br/>
            11、其他说明:<br/>
            (1)目前视频彩铃业务仅支持4G/5G用户使用。<br/>
            (2)如您设置了主叫彩铃，当您拨打电话时，您的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间可以看到自己订阅的视频彩铃;如您设置了彩振一体，当您的手机型号(需开通VOLTE服务)支持“彩振一体”，Ta的手机型号(需开通VOLTE服务)支持“视频彩铃’时，通话等待期间您和Ta均可以看到您订阅的视频彩铃，否则将播放音频彩铃。点击检测手机型号是否支持。<br/>
            (3)关于免流服务，是我们面向会员产品订购用户推出的福利政策在免流服务下，在活动营销页和“联通视频彩铃”微信小程序观看短剧内容时，免收取流量费用。免流服务仅供中国大陆地区使用，不含中国港澳台地区、国外地区。不支持结转、转赠、与他人共享等。<br/>
            (4)如有疑问或咨询，请致电0511-85985595。<br/>
            12、业务提供方：中文在线集团股份有限公司
          </p>
        </div>
      </div>
      <el-image :src="require('@/assets/img/bottom.png')"></el-image>
      <div class="videoPlay-container" v-if="showVideo">
        <video id="video" controls preload="auto" playsinline :poster="videoImgSrc" webkit-playsinline
          x5-video-player-fullscreen x5-video-player-type="h5" x5-video-orientation="portraint"
          class="video-play video_shu" autoplay>
          <source :src="videoSrc" type="video/mp4" />
        </video>
        <div class="left-arrow" @click="colseVideo">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAqBAMAAABb64ZtAAAAElBMVEUAAAD///////////////////8+Uq06AAAABXRSTlMABrbnh0QwqGcAAABISURBVCjPY4ADEWNHOJtRNTQEzhEKDQ1GSIA4CAmgMoREqCKSRJDAYJZgcEWSYDCFSSA4GMoQBsCMHnpSIejRiIhg9KhHJAoAtpM1R80G5xQAAAAASUVORK5CYII="
            alt="" class="left-img" />
        </div>
        <div class="phone_box">
          <div class="video-title">{{ videoDesc }}</div>
        </div>
      </div>
      <!---->
      <div class="rule" v-show="showRule">
        <div class="overlay">
          <div class="dialog">
            <div class="rule_img">
              <div class="rule_title">活动须知</div>
              <div class="ruletext">
                （1）仅支持中国联通用户；<br />
                （2）业务订购扣费后立即生效，用户当月如不退订，次月1日系统自动扣除包月费用，每月自动续订，从话费中扣取费用。用户当月退订成功，次月不再收取包月费用，权益可使用至当月月末，次月失效；<br />
                （3）会员产品服务包含短剧不限次免流观看和短剧彩铃订阅设置，在产品订购有效期间，您可以搜索“联通视频彩铃”微信小程序，在首页视彩号专区—短剧频道，不限次观看频道内短剧内容，或订阅精选短剧彩铃为主叫彩铃或彩振一体。<br />
                （4）如您设置了主叫彩铃，当您拨打电话时，您的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间可以看到自己订阅的视频彩铃；如您设置了彩振一体，当您的手机型号(需开通VOLTE服务)支持“彩振一体”，Ta的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间您和Ta均可以看到您订阅的视频彩铃，否则将播放音频彩铃；<br />
                （5）关于免流服务，是我们面向会员产品订购用户推出的福利政策，在免流服务下，在活动营销页和“联通视频彩铃”微信小程序观看短剧内容时，免收取流量费用。免流服务仅供中国大陆地区使用，不含中国港澳台地区、国外地区。不支持结转、转赠、与他人共享等。<br />
                （6）彩铃设置、短剧观看、免流观剧等会员服务将根据资源合作情况不定期调整，免流服务可能应不可抗力或第三方原因发生变更，请以页面展示为准；<br />
                （7）如需退订，请联系客服热线：4000410155
              </div>
            </div>
            <div class="close_btn" @click="closeRule"></div>
          </div>
        </div>
      </div>

      <div class="code-new" v-show="showCode">
        <div class="overlay">
          <div class="dialog">
            <div class="code-content">
              <div class="title">登录</div>
              <div class="bg-content">
                <input type="tel" class="input-phone" placeholder="请输入联通手机号码" maxlength="11" v-model="phoneNumber" />
                <!--<div class="smsbox">
                  <input type="tel" placeholder="请输入验证码" class="input-sms" maxlength="6" v-model="verifyCode" /><button
                    class="btn-sms" v-if="!getCodeFlag" @click="getCode">
                    获取验证码</button><button class="btn-sms" v-if="getCodeFlag">
                    {{ countdown }}
                  </button>
                </div>-->
                <div class="btn-login" @click="search">
                  <span>立即登录</span>
                </div>
              </div>
              <div alt="" class="close" @click="closeCode"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="instruct-new" v-show="showInstruct">
        <div class="overlay">
          <div class="dialog">
            <div class="code-content">
              <div class="title">温馨提示</div>
              <div class="bg-content">
                <div class="text"><p>{{ qyInstruct }}</p></div>
                <div class="btn-login" @click="exchange">
                  <span>立即领取</span>
                </div>
              </div>
              <div alt="" class="close" @click="closeInstruct"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import videojs from "video.js";
import "swiper/css/swiper.min.css"
import Swiper from "swiper"; // 导入您需要的模块
export default {
  name: "Womusic",
  data() {
    return {
      showVideo: false,
      hasChecked: false,
      showRule: false,
      titleList: [],
      videoList: [
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744266419304026113.jpg",
          videoDesc: "旷世逍遥神医",
          videoSrc:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744266343940870145.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001680&id=05EA3F77CC01DB932AE94E579AF9137F&timestamp=1710228572&isSegment=0",
        },
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240109/1744610824698769409.jpg",
          videoDesc: "重回1998",
          videoSrc: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744276177436258306.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085004390&id=0AF2A9B1CAA504685BBC7EEF197530F3&timestamp=1710232825&isSegment=0"
        },
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744282467008303106.jpg",
          videoDesc: "女总裁的嚣张保镖",
          videoSrc: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744281538163617794.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001710&id=207A7AEA649CFBB03E2AFCB793E3CFE5&timestamp=1710232843&isSegment=0"
        },
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744293270218387458.jpg",
          videoDesc: "战神令",
          videoSrc: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744292848003629058.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085005820&id=BC2E61B05BDE72D4E7646586CD083637&timestamp=1710232865&isSegment=0"
        },
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240112/1745699074288955394.jpg",
          videoDesc: "婚礼上我换新郎",
          videoSrc: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744295027481505794.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085006030&id=BC7E0A019E60751E3290D518E8A5E7CB&timestamp=1710232894&isSegment=0"
        },
        {
          videoImg:
            "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240112/1745702046771228674.jpg",
          videoDesc: "总裁的天价前妻",
          videoSrc: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744296302788911106.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085006210&id=3BDE4B11286CF95C3C236FE142896170&timestamp=1710232950&isSegment=0"
        },
      ],
      historyList: [
        {
          time: "1分钟前",
          name: "陈**",
          phone: "185*****404",
          desc: "已开启追剧模式",
        },
        {
          time: "3分钟前",
          name: "方**",
          phone: "176*****099",
          desc: "已开启追剧模式",
        },
        {
          time: "4分钟前",
          name: "钟**",
          phone: "130*****304",
          desc: "已开启追剧模式",
        },
        {
          time: "5分钟前",
          name: "王**",
          phone: "166*****276",
          desc: "已开启追剧模式",
        },
        {
          time: "5分钟前",
          name: "卢**",
          phone: "130*****639",
          desc: "已开启追剧模式",
        },
      ],
      scrollOffset: 0,
      scrollSpeed: 0.3, // 调整滚动速度
      offsetIndex: 0,
      offsetSum: 0,
      offsetFlag: false,
      showCode: false,
      phoneNumber: "",
      orderPhoneNumber: "",
      verifyCode: "",
      getCodeFlag: false,
      countdown: "",
      channelCode: "",
      videoSrc: "",
      videoDesc: "",
      videoImgSrc: "",
      player: null,
      controls: true,
      isLogin: false,
      hasOrdered: false,
      mySwiper: null,
      activeName: "super",
      xlqy: [
        { imgSrc: require('@/assets/img/qy-logo/aqy.png'), desc: "爱奇艺视频(月卡)", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/txsp.jpg'), desc: "腾讯视频VIP(月卡)", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/yk.jpg'), desc: "优酷视频会员(月卡)", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/mg.jpg'), desc: "芒果TV(月卡)", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/blbl.png'), desc: "哔哩哔哩(月卡)", price: "￥25", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/qqyy.jpg'), desc: "qq音乐绿钻(月卡)", price: "￥18", instruct: "1、请在兑换前仔细确认兑换QQ号码及权益类型，如需修改，请在下框输入正确账号，一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n2、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/kg.png'), desc: "酷狗音乐(月卡)", price: "￥18", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/wyy.png'), desc: "网易云音乐(月卡)", price: "￥18", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/xmly.png'), desc: "喜马拉雅(月卡)", price: "￥25", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/wps.jpg'), desc: "wps超级会员(月卡)", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/keep.png'), desc: "keep会员月卡", price: "￥25", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/zgsh.jpg'), desc: "中石化满200减30卡", price: "￥30", instruct: "1、用户复制链接https://wechat.chengquan.cn/config/exchange/index?cfg=93，打开链接，输入加油卡账号. 本商品为中国石化加油卡直充，支持中石化IC卡充值，注意只能充主卡，副卡和字母开头的加油卡不能充值。中国石化加油卡是1000开头的19位卡。\r\n2、充值范围: 全国各地的中石化加油卡均可充值。\r\n3、充值时间:本产品为自动充值，付款后1-30分钟到账。每天晚上22:50至次日凌晨0:50为中石化系统维护时间。所以在此时间段内订单会延迟到凌晨1点左右开始充值,请知晓。\r\n4、充值查询:请登录中国石化官网www.sino-pecsales.com或拔打中石化官方客服电话95105888/95105988查询，短消息提示是中国石化系统发出的，不可控制，有可能没有收到短信，但是实际已经充值成功了，请知悉。\r\n5、圈存提醒:充值成功后，需持加油卡到任意一家中国石化加油站圈存，全天24小时都可以办理圈存，圈存后才能使用。未圈存前金额可在备付金账户内查询(首次操作可咨询加油站工作人员)。" },
        { imgSrc: require('@/assets/img/qy-logo/mdl.jpg'), desc: "麦当劳代金券20元", price: "￥20", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、搜索“麦当劳”微信/支付宝小程序或者打开“麦当劳”APP，点击“我的”-“兑换券”，输入券号后点击“立即兑换”即可兑换麦当劳券。" },
        { imgSrc: require('@/assets/img/qy-logo/bsk.jpg'), desc: "必胜客20元代金券", price: "￥20", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、打开必胜客APP或微信公众号—我的—神钱包—礼品卡绑定—输入卡密点击“绑定”即可。" },
        { imgSrc: require('@/assets/img/qy-logo/tld.jpg'), desc: "特来电20元充电服务券", price: "￥20", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、充电服务有效期自绑定之日起【93】日，若在有效期内未消耗完毕，不可延期或退还。\r\n4、使用范围：仅限特来电自营场站（特殊场站除外）及部分非自营场站抵扣服务费。" },
        { imgSrc: require('@/assets/img/qy-logo/stc.jpg'), desc: "速停车20元券", price: "￥20", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、关注速停车公众号(仅限本手机号关联的速停车公众号账号使用)，停车费需大于停车券面值方可使用,每次限用一张且不可以与其他优惠券叠加使用，停车券不可拆分、不可折现。停车券自发放之时起3日内有效，过期未使用则自动作废且不予补发，请在有效期内尽快使用。" },
        { imgSrc: require('@/assets/img/qy-logo/kdj.jpg'), desc: "肯德基礼品卡20元", price: "￥20", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、在兑换记录中，查询兑换码，复制链接跳转二维码直接付款即可。" },
        { imgSrc: require('@/assets/img/qy-logo/bd.png'), desc: "百度文库会员", price: "￥25", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/qj.jpg'), desc: "滴滴青桔单车月卡", price: "￥25", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/bdcjhy.jpg'), desc: "百度网盘超级会员", price: "￥30", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
      ],
      czqy: [
        { imgSrc: require('@/assets/img/qy-logo/kwyy.png'), desc: "酷我音乐(月卡)", price: "￥15", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/elm.png'), desc: " 饿了么超级月卡", price: "￥15", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/mt.png'), desc: "美团外卖无门槛券10元", price: "￥10", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/wps.jpg'), desc: "wps普通会员", price: "￥15", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/bdwp.jpg'), desc: "百度网盘会员", price: "￥10", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/xunlei.png'), desc: "迅雷自金会员(月卡)", price: "￥15", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/qqpthy.jpg'), desc: "QQ普通会员", price: "￥10", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/txsp.jpg'), desc: "腾讯视频(周卡)", price: "￥13", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },
        { imgSrc: require('@/assets/img/qy-logo/qqhz.jpg'), desc: "QQ黄钻会员(月卡)", price: "￥10", instruct: "1、您在平合兑换权益时，兑换号码与订购号码必须一致，不允许更换。\r\n2、此权益一经兑换，无法更换。兑换后请通过兑换号码进行查询。\r\n3、直充即时到账，登录APP后可以享受相关权益。" },

      ],
      oauthToken: "",
      qyInstruct: "",
      showInstruct: false,
    };
  },
  methods: {
    clickTop() {
      console.log('clickTop')
      if (!this.isLogin) {
        this.$message.closeAll();
        this.$message("请先登录");
        return;
      }
      if (!this.phoneNumber) {
        this.$message.closeAll();
        this.$message("请先登录");
        return;
      }
      let params = {
        phoneNumber: this.phoneNumber,
      };
      this.$api.check(params).then((res) => {
        console.log(res);
        if (res.status) {
          res = res.status;
        }
        if (res.success) {
          if (res.hasOrdered) {
            //this.$message("您已订购该产品");
            window.location.href = "http://asai.4yt.cn/?phoneNumber=" + this.phoneNumber;
          } else {
            this.$message.closeAll();
            this.$message("您还未订购该产品");
            return;
          }
        } else {
          this.$message.closeAll();
          this.$message.error(res.message);
          return;
        }
      });

    },
    login() {
      let params = {
        phoneNumber: this.phoneNumber,
        verifyCode: this.verifyCode,
      };
      this.$api.login(params).then((res) => {
        console.log(res);
        if (res.status) {
          res = res.status;
        }
        if (res.success) {
          this.showCode = false;
          this.oauthToken = res.oauthToken;
          this.$api.check(params).then((res) => {
            console.log(res);
            if (res.status) {
              res = res.status;
            }
            if (res.success) {
              this.isLogin = true;
              this.hasOrdered = res.hasOrdered;
              
            } else {
              this.$message.closeAll();
              this.$message.error(res.message);
              return;
            }
          });
        } else {
          this.$message.closeAll();
          this.$message.error(res.message);
        }
      });
    },
    movieClick() {
      console.log('movieClick')
      let url = "http://activity1.jsbc0505.com/wovideo/wohome_fyt/index.html?oauthToken=" + this.oauthToken;
      if (this.isLogin) {
        window.location.href = url;
      } else {
        this.$message.closeAll();
        this.$message("请先登录！");
      }
    },
    exchange() {
      if (!this.isLogin) {
        this.$message.closeAll();
        this.$message("请先登录");
        return;
      }
      if (!this.phoneNumber) {
        this.$message.closeAll();
        this.$message("请先登录");
        return;
      }
      let params = {
        phoneNumber: this.phoneNumber,
      };
      this.$api.check(params).then((res) => {
        console.log(res);
        if (res.status) {
          res = res.status;
        }
        if (res.success) {
          if (res.hasOrdered) {
            //this.$message("您已订购该产品");
            window.location.href = "https://6gmm.com/wx/music/zwwomusic.html?phoneNumber=" + this.phoneNumber;
          } else {
            this.$message.closeAll();
            this.$message("您还未订购该产品");
            return;
          }
        } else {
          this.$message.closeAll();
          this.$message.error(res.message);
          return;
        }
      });
      
    },
    lqqy(item) {
      
      this.qyInstruct = item.instruct;
      this.openInstructDialog();
      //window.location.href = "https://6gmm.com/wx/music/xzhdyt.html";
    },
    openInstructDialog() {
      this.showInstruct = true;
    },
    logout() {
      this.isLogin = false;
    },
    colseVideo() {
      this.showVideo = false;
    },
    videoClick(videoImg, videoSrc, videoDesc) {
      console.log(videoImg, videoSrc, videoDesc);
      this.videoSrc = videoSrc;
      this.videoDesc = videoDesc;
      this.videoImgSrc = videoImg;
      this.showVideo = true;
    },
    showYsxy() {
      window.location.href = "https://m.10155.com/h5/mactivity/#/privacy";
    },
    showYhxy() {
      window.location.href =
        "https://m.10155.com/h5/mactivity/index.html#/service";
    },
    showMoreVideo() {
      window.location.href =
        "https://m.10155.com/h5/mactivity/index.html#/openmp?path=subpages%2FshakeList%2FshakeList&channelId=3000016027";
    },
    ruleClick() {
      this.showRule = true;
    },
    closeRule() {
      this.showRule = false;
    },
    openCode() {
      if (!this.hasChecked) {
        this.$message.closeAll();
        this.$message("您还没勾选同意《隐私协议》和《用户协议》");
        return;
      }
      this.showCode = true;
      if (this.phoneNumber) {
        //this.getCode()
      }
    },
    closeInstruct() {
      this.showInstruct = false;
    },
    closeCode() {
      this.showCode = false;
    },
    raidoClick() {
      const hasChecked = this.hasChecked;
      this.hasChecked = !hasChecked;
    },
    checkPhoneNumber(phoneNumber) {
      var reg = /0?(13|14|15|18|17)[0-9]{9}/;
      return reg.test(phoneNumber);
    },
    order() {
      let orderPhoneNumber = this.phoneNumber;
      if (!orderPhoneNumber) {
        this.$message.closeAll();
        this.$message("手机号不能为空！");
        return;
      }
      if (!this.checkPhoneNumber(orderPhoneNumber)) {
        this.$message.closeAll();
        this.$message("您的手机号输入有误！");
        return;
      }
      this.openCode();
    },
    updateScrollOffset() {
      this.scrollOffset -= this.scrollSpeed;
      this.offsetSum += this.scrollSpeed;
      this.offsetIndex = this.offsetIndex + 1;
      if (this.offsetIndex >= this.historyList.length) {
        this.offsetIndex = 0;
        this.$el.querySelectorAll(".van-swipe-item").forEach((item) => {
          item.style.transform = `translateY(${this.offsetSum}rem)`;
        });
      }
    },
    getCode() {
      let phone = this.phoneNumber;
      if (!phone) {
        this.$message.closeAll();
        this.$message("手机号不能为空！");
        return;
      }
      if (!this.checkPhoneNumber(phone)) {
        this.$message.closeAll();
        this.$message("您的手机号输入有误！");
        return;
      }
      this.sendLoginCode(phone);
    },
    sendLoginCode(phone) {
      let params = {
        phoneNumber: phone,
      };
      this.$api.sendLoginCodeNew(params).then((res) => {
        console.log(res);
        if (res.status) {
          res = res.status;
        }
        if (res.success) {
          this.startCountdown();
          this.getCodeFlag = true;
        } else {
          this.$message.closeAll();
          this.$message.error(res.message);
        }
      });
    },
    startCountdown() {
      let seconds = 60; // 设置倒计时的秒数

      const timer = setInterval(() => {
        if (seconds > 0) {
          this.countdown = `倒计时${seconds}s`; // 更新显示的倒计时文本
          seconds--; // 每次递减1秒
        } else {
          clearInterval(timer); // 当倒计时结束时清除定时器
          this.countdown = ""; // 可选：将倒计时文本重置为特定内容
          this.getCodeFlag = false;
        }
      }, 1000); // 每隔1秒执行一次
    },
    search() {
      if (!this.hasChecked) {
        this.$message.closeAll();
        this.$message("您还没勾选同意《隐私协议》和《用户协议》");
        return;
      }
      if (!this.phoneNumber) {
        this.$message.closeAll();
        this.$message("请先输入手机号！");
        return;
      }
      if (!this.checkPhoneNumber(this.phoneNumber)) {
        this.$message.closeAll();
        this.$message("您的手机号输入有误！");
        return;
      }
      //if (!this.verifyCode) {
      //  this.$message("请先输入验证码！");
      //  return;
      //}
      this.login();
    },
  },
  //初始化播放器
  mounted() {
    var windowWidth = document.documentElement.clientWidth;
    console.log('windowWidth', windowWidth);
    // 2. 以标准的375屏幕 根元素100px的标准  需要3.75rem占满整屏的值 作为参照点
    var htmlFontSize = windowWidth / 7.5;
    //给最大值 超过了200px默认为200px
    if (htmlFontSize > 85) {
      htmlFontSize = 85;
    }
    this.mySwiper = new Swiper('.swiper-container', {
      width: htmlFontSize * 3.8 * 2,
      direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
      loop: true, // 设置为true 则开启loop模式  衔接滑动

      effect: "coverflow", // 切换效果 "coverflow"（3d流）
      slidesPerView: 2, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
      centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
      slideToClickedSlide: true, // 设置为true则点击slide会过渡到这个slide。
      // loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
      coverflowEffect: {
        rotate: 0, // slide做3d旋转时Y轴的旋转角度
        stretch: 0, // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 60, // slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 5, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
        slideShadows: false // 是否开启slide阴影
      },

      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
      },
      // 其他Swiper配置...
    });
    console.log('mySwiper', this.mySwiper)

    let options = {
      autoplay: true, // 自动播放
      language: "zh-CN",
      controls: this.controls, // 控制条
      preload: "auto", // 自动加载
      errorDisplay: true, // 错误展示
      // fluid: true, // 跟随外层容器变化大小，跟随的是外层宽度
      // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但是存在
      // textTrackDisplay: false,  // 不渲染字幕相关DOM
      userActions: {
        hotkeys: true, // 是否支持热键
      },
      notSupportedMessage: "此视频暂无法播放，请稍后再试",
      techOrder: ["h5", "flash"], // 定义Video.js技术首选的顺序
      sources: [
        {
          src: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744266343940870145.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001680&id=05EA3F77CC01DB932AE94E579AF9137F&timestamp=1710228572&isSegment=0", // 视频或者直播地址
          type: "video/mp4",
          //type: 'rtmp/flv',
        },
      ],
    };

    this.player = videojs(
      this.$refs.videoNode,

      options,

      function onPlayerReady() {
        videojs.log(`Your player is ready!`);
      }
    );


  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created() {
    this.channelCode = this.$route.query.cmcode;
    //console.log(this.channelCode)
  },
};
</script>

<style scoped>
@import "../../assets/css/womusic.css";
</style>
<<style scoped lang="less">
::v-deep .el-tabs__nav {
  width: 100%;

  .el-tabs__item {
    width: 50%;
    text-align: center;
    padding: 0 !important;
  }

  .el-image {
    width: 100% !important;
  }

}

/* 去掉长分割线 */
:deep(.el-tabs__nav-wrap::after) {
  position: static !important;
}

/* 去掉下划线 */
:deep(.el-tabs__active-bar) {
  background-color: transparent !important;
}



.footer-new {
  ::v-deep .el-divider--horizontal {
    margin: 0.1rem 0;
  }
}
</style>