//文件为 config/rem.js
export default function(){
    setHtmlFontSize()

    function setHtmlFontSize() {
        // 1. 获取当前的屏幕的宽度
        var windowWidth = document.documentElement.clientWidth;
        console.log('windowWidth', windowWidth);
        // 2. 以标准的375屏幕 根元素100px的标准  需要3.75rem占满整屏的值 作为参照点
        var htmlFontSize = windowWidth / 7.5;
        //给最大值 超过了200px默认为200px
        if(htmlFontSize > 85){
            htmlFontSize = 85;
        }
        // 3. 把当前根元素的字体大小设置给当前html的fontSize 注意要带单位
        document.querySelector('html').style.fontSize = htmlFontSize + 'px';
    }
}
