<template>
    <div id="app">
      <div class="video-container" id="mainElement">
        <img src="@/assets/img/header.jpg" class="top_bg" alt="" />
        <div class="rule_btn" @click="ruleClick" style="background: none;"></div>
        <div class="login-content">
          <div class="login_msg">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAAXNSR0IArs4c6QAAAeBJREFUaEPlmmuSwyAIgOVm25M1PVnbk7lDRrMmRUXRBLrOdPpHI588RBTcRc17/+Ocuzvn8P+FPwB4SMUB6Qd6xnvvnwHkOHyRQp0KdNAKuRYAIJJJNLhFOwEGNVNrNwBAE+xqpwB579FXFqaEIrObDtQIg8x6gQrOX1KWPqAGf6HAdAF1mNjQ0D3UhwbA6PGhTn/RZ3KczZIZrmO363xI6Pw5TjnQhFVuVMquexUo+ComtdgeaWYBk1Z5GlAm8GzpEgK1pCUSQbljixrKyLuNQSDPnemkfj1ALmbpFoHQd6isfV0Ic0BoJRmrMg1E+j2anUkNFbVkLSjEwJRJtRbLGqLM7uuAXt+mIdNA5H5kWUP/Asi0yVFRzjQQVR83HbYpoJvJoFA4lJoFIq9jzCanmfwTL8zsaahobgBrlMvdpp104v6YpnYEp+RdtYNfsghE7T9/VZ/CYUmrhj6A0mvMrVh/KN5xYWKxj9uf049TaIw36Hh1+d4VGjkzlPpM8MEqUEmeIdcpg4uV1wMFPxxVgdUBNBBKD1CASp+89LioLqCkzNRrgjqBBCaoF6gTSjdQ4lecdz7YXT9QpXx7DBz6Hy+lEtc2YTPPy5hQInNbjw89G8WoMUFb8XO7JLN3jl/lQHuWRoSPcAAAAABJRU5ErkJggg=="
              class="horn"
              alt=""
            />
            <div class="msg_text">
              <div
                class="van-swipe my-swipe scroll-banner"
                style="height: 0.3rem"
              >
                <div
                  class="van-swipe__track van-swipe__track--vertical"
                  :style="{
                    'transition-duration': '500ms',
                    height: `130px`,
                    transform: `translateY(${scrollOffset}rem)`,
                  }"
                >
                  <div
                    class="van-swipe-item"
                    style="height: 0.3rem"
                    v-for="(item, index) in historyList"
                  >
                    <div class="banner-item">
                      <span class="time_font">{{ item.time }}</span>
                      <div class="right_text">
                        {{ item.name }}
                        <span class="bold_font">{{ item.phone }}</span>
                        {{ item.desc }}
                      </div>
                    </div>
                  </div>
                </div>
                <!---->
              </div>
            </div>
          </div>
          <!----><!----><!---->
          <div>
            <input
              class="login_input"
              id="login_input"
              maxlength="11"
              v-model="orderPhoneNumber"
              placeholder="请输入11位联通号码"
            />
            <div class="login_btn" @click="order"></div>
            <div class="raido">
              <div
                :class="!hasChecked ? 'inputs' : 'inputs checked'"
                @click="raidoClick"
              >
                <div class="haschecked" v-if="hasChecked"></div>
              </div>
              我已阅读并同意 <span @click="showYsxy"> 《隐私协议》 </span> 和
              <span @click="showYhxy"> 《用户协议》 </span>
            </div>
            <div class="vip_login" @click="openCode">
              <span>我是会员，登录免流畅看精品短剧 &gt;</span>
            </div>
            <!---->
          </div>
        </div>
        <div class="list-container">
          <div class="list_title">
            <span class="title_text">热门短剧免流畅看</span>
          </div>
          <div class="list-wrapper">
            <!--<div
              class="swiper swiper-initialized swiper-horizontal swiper-pointer-events tabSwiper swiper-backface-hidden"
              slidwidth="2rem"
            >
              <div
                class="swiper-wrapper"
                style="transform: translate3d(0px, 0px, 0px)"
              >
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">旷世逍遥神医</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">重回1998</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">女总裁的嚣张保镖</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">战神令</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">婚礼上我换新郎</div>
                </div>
                <div class="swiper-slide item_wrapper">
                  <div class="tab-name">总裁的天价前妻</div>
                </div>
              </div>
            </div>-->
            <div class="subscribe-container">
              <div class="subscribe_btn" @click="showMoreVideo">
                <span>看更多热门短剧</span>
              </div>
            </div>
            <div class="video-list">
              <div class="list-item" v-for="(item, index) in videoList">
                <div class="video-box">
                  <img
                    alt="img"
                    class="video-img"
                    :src="item.videoImg"
                    :data-src="item.videoImg"
                    lazy="loaded"
                  />
                  <div
                    class="play_btn"
                    @click="
                      videoClick(item.videoImg, item.videoSrc, item.videoDesc)
                    "
                  >
                    <span>免费试看</span>
                  </div>
                </div>
                <div class="video_desc">{{ item.videoDesc }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <img src="@/assets/img/footer.jpg" class="" style="width: 100%;" alt="" />
        </div>
        <div class="footer-context">
            <p class="text">1.<span class="yellow">活动时间</span>：2024年05月01日-2024年12月31日</p>
            <p class="text">2.<span class="yellow">活动对象</span>：全国，中国联通手机用户</p>
            <p class="text">3.<span class="yellow">支付方式</span>：仅支持联通话费支付，为用户提供包月服务。本活动业务为自动续费产品，用户订购期内不退订，下月则视为自动续订。业务资费25元/月。</p>
            <p class="text">4.<span class="yellow">活动介绍</span>：活动期间凡首次通过本活动页面成功开通“视频彩铃数字微剧会员25元”的用户可获取以下多重权益：</p>
            <p class="text"><span class="yellow">【权益一：热门会员月卡N选1】</span>会员权益包含爱奇艺会员月卡、腾讯视频会员月卡、优酷会员月卡、芒果会员月卡、喜马拉雅月卡、网易云音乐月卡、QQ音乐绿钻月卡、饿了么超级月卡、美团外卖无门槛10元卷、keep月卡会员、哔哩哔哩月卡、酷我音乐月卡、百度文库会员、百度网盘会员、滴滴青桔单车月卡、迅雷白金会员、QQ普通会员、腾讯视频周卡、酷狗音乐月卡、QQ黄钻会员月卡，具体以权益领取页面为准。</p>
            <p class="text"><span class="yellow">【权益二：彩铃设置】</span>订阅精选短剧彩铃为主叫彩铃或彩振一体。如您设置了主叫彩铃，当您拨打电话时，您的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间可以看到自己订阅的视频彩铃；如您设置了彩振一体，当您的手机型号(需开通VOLTE服务)支持“彩振一体”，Ta的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间您和Ta均可以看到您订阅的视频彩铃，否则将播放音频彩铃。</p>
            <p class="text"><span class="yellow">【权益三：短剧观看】</span>会员产品服务包含短剧不限次免流观看和短剧彩铃订阅。</p>
            <p class="text">5.<span class="yellow">福利使用方式</span></p>
            <p class="text">（1）权益兑换：点击 &nbsp;<a href="https://6gmm.com/wx/music/xzhdyt.html" class="yellow">https://6gmm.com/wx/music/xzhdyt.html</a></p>
            <p class="text">--点击“登录领取”相关权益即可。兑换成功后可前往权益所在平台激活使用。</p>
            <p class="text">（2）彩铃设置：搜索“联通视频彩铃”微信小程序，在发现页—视彩号专区—短剧频道，订阅精选短剧彩铃为主叫彩铃或彩振一体。</p>
            <p class="text">（3）短剧免流观看：搜索“联通视频彩铃”微信小程序，在发现页—视彩号专区—短剧频道，不限次观看频道内短剧内容。</p>
            <p class="text">6.<span class="yellow">注意事项</span>：</p>
            <p class="text">（1）每个手机号仅限参与一次，本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。</p>
            <p class="text">（2）本业务退订后，从次月1号起不再享有短剧观看权限和领取热门权益，已产生的业务订购费用不予退还。（退订后如需要领取退订当月的会员权益请联系客服领取，如已领取，仍可使用至月末）。</p>
            <p class="text">（3）如活动期内相关热门权益因库存不足或其他原因暂时无法兑换，用户可致电客服热线4000571349选择当月内延后兑换或者进行其他等价值的权益兑换。</p>
            <p class="text">（4）成功开通“视频彩铃数字微剧会员25元”包月业务，未退订状态下可连续获赠送不超过12个月热门权益，权益领取周期为自然月。每月热门权益需用户手动领取，如当月热门权益未领取，次月无法补领上月权益，请及时领取。</p>
            <p class="text">（5）使用相关权益时，请遵守相应平台的用户服务协议和权益使用协议，如违反相关协定规定，联合权益提供方均有权单独进行处理，包括但不限于中止或终止提供其所属平台的服务及要求侵权赔偿等。</p>
            <p class="text">7.<span class="yellow">订购查询</span>：本活动业务为自动续费产品，用户订购期内不退订，下月将进行自动扣费续订。</p>
            <p class="text">（订购关系查询:下载并登录中国联通APP-服务-流量语音-详单查询-增值业务，即可查询。）</p>
            <p class="text">8.<span class="yellow">退订方式</span>：</p>
            <p class="text">（1） 登录中国联通APP-点击“服务”-“号卡套餐”-“已订业务”-点击“视频彩铃数字微剧会员25元”&nbsp;“退订”按钮，进行退订。</p>
            <p class="text">9.<span class="yellow">客服热线</span>：如有疑问可致电客服热线：4000410155，服务时间：周一至周日，08:00-12:00,13:00-18:00</p>
            <p class="text">10.<span class="yellow">产品提供方</span>：北京中文在线文化传媒有限公司</p>
            <p class="text">11.<span class="yellow">服务提供方</span>：联通沃音乐文化有限公司</p>
            <p class="text"></p>
        </div>
        <div class="videoPlay-container" v-if="showVideo">
          <video
            id="video"
            controls
            preload="auto"
            playsinline
            :poster="videoImgSrc"
            webkit-playsinline
            x5-video-player-fullscreen
            x5-video-player-type="h5"
            x5-video-orientation="portraint"
            class="video-play video_shu"
            autoplay
          >
            <source :src="videoSrc" type="video/mp4" />
          </video>
          <div class="left-arrow" @click="colseVideo">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAqBAMAAABb64ZtAAAAElBMVEUAAAD///////////////////8+Uq06AAAABXRSTlMABrbnh0QwqGcAAABISURBVCjPY4ADEWNHOJtRNTQEzhEKDQ1GSIA4CAmgMoREqCKSRJDAYJZgcEWSYDCFSSA4GMoQBsCMHnpSIejRiIhg9KhHJAoAtpM1R80G5xQAAAAASUVORK5CYII="
              alt=""
              class="left-img"
            />
          </div>
          <div class="phone_box">
            <div class="video-title">{{ videoDesc }}</div>
          </div>
        </div>
        <!---->
        <div class="rule" v-show="showRule">
          <div class="overlay">
            <div class="dialog">
              <div class="rule_img">
                <div class="rule_title">活动须知</div>
                <div class="ruletext">
                  （1）仅支持中国联通用户；<br />
                  （2）业务订购扣费后立即生效，用户当月如不退订，次月1日系统自动扣除包月费用，每月自动续订，从话费中扣取费用。用户当月退订成功，次月不再收取包月费用，权益可使用至当月月末，次月失效；<br />
                  （3）会员产品服务包含短剧不限次免流观看和短剧彩铃订阅设置，在产品订购有效期间，您可以搜索“联通视频彩铃”微信小程序，在首页视彩号专区—短剧频道，不限次观看频道内短剧内容，或订阅精选短剧彩铃为主叫彩铃或彩振一体。<br />
                  （4）如您设置了主叫彩铃，当您拨打电话时，您的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间可以看到自己订阅的视频彩铃；如您设置了彩振一体，当您的手机型号(需开通VOLTE服务)支持“彩振一体”，Ta的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间您和Ta均可以看到您订阅的视频彩铃，否则将播放音频彩铃；<br />
                  （5）关于免流服务，是我们面向会员产品订购用户推出的福利政策，在免流服务下，在活动营销页和“联通视频彩铃”微信小程序观看短剧内容时，免收取流量费用。免流服务仅供中国大陆地区使用，不含中国港澳台地区、国外地区。不支持结转、转赠、与他人共享等。<br />
                  （6）彩铃设置、短剧观看、免流观剧等会员服务将根据资源合作情况不定期调整，免流服务可能应不可抗力或第三方原因发生变更，请以页面展示为准；<br />
                  （7）如需退订，请联系客服热线：4000410155
                </div>
              </div>
              <div class="close_btn" @click="closeRule"></div>
            </div>
          </div>
        </div>
  
        <div class="code" v-show="showCode">
          <div class="overlay">
            <div class="dialog">
              <div class="code-content">
                <div class="title">登录查询</div>
                <div class="bg-content">
                  <input
                    type="tel"
                    class="input-phone"
                    placeholder="请输入正确的11位手机号码"
                    maxlength="11"
                    v-model="phoneNumber"
                  />
                  <!--<div class="smsbox">
                    <input
                      type="tel"
                      placeholder="请输入验证码"
                      class="input-sms"
                      maxlength="6"
                      v-model="verifyCode"
                    /><button
                      class="btn-sms"
                      v-if="!getCodeFlag"
                      @click="getCode"
                    >
                      获取验证码</button
                    ><button class="btn-sms" v-if="getCodeFlag">
                      {{ countdown }}
                    </button>
                  </div>-->
                  <div class="btn-login" @click="search">
                    <span>查询</span>
                  </div>
                </div>
                <div alt="" class="close" @click="closeCode"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import videojs from "video.js";
  
  export default {
    name: "Womusic",
    data() {
      return {
        showVideo: false,
        hasChecked: false,
        showRule: false,
        titleList: [],
        videoList: [
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744266419304026113.jpg",
            videoDesc: "旷世逍遥神医",
            videoSrc:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744266343940870145.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001680&id=05EA3F77CC01DB932AE94E579AF9137F&timestamp=1710228572&isSegment=0",
          },
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240109/1744610824698769409.jpg",
            videoDesc: "重回1998",
            videoSrc:"https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744276177436258306.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085004390&id=0AF2A9B1CAA504685BBC7EEF197530F3&timestamp=1710232825&isSegment=0"
          },
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744282467008303106.jpg",
            videoDesc: "女总裁的嚣张保镖",
            videoSrc:"https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744281538163617794.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001710&id=207A7AEA649CFBB03E2AFCB793E3CFE5&timestamp=1710232843&isSegment=0"
          },
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240108/1744293270218387458.jpg",
            videoDesc: "战神令",
            videoSrc:"https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744292848003629058.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085005820&id=BC2E61B05BDE72D4E7646586CD083637&timestamp=1710232865&isSegment=0"
          },
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240112/1745699074288955394.jpg",
            videoDesc: "婚礼上我换新郎",
            videoSrc:"https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744295027481505794.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085006030&id=BC7E0A019E60751E3290D518E8A5E7CB&timestamp=1710232894&isSegment=0"
          },
          {
            videoImg:
              "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volteposter/verticalpath/20240112/1745702046771228674.jpg",
            videoDesc: "总裁的天价前妻",
            videoSrc:"https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744296302788911106.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085006210&id=3BDE4B11286CF95C3C236FE142896170&timestamp=1710232950&isSegment=0"
          },
        ],
        historyList: [
          {
            time: "1分钟前",
            name: "陈**",
            phone: "185*****404",
            desc: "已开启追剧模式",
          },
          {
            time: "3分钟前",
            name: "方**",
            phone: "176*****099",
            desc: "已开启追剧模式",
          },
          {
            time: "4分钟前",
            name: "钟**",
            phone: "130*****304",
            desc: "已开启追剧模式",
          },
          {
            time: "5分钟前",
            name: "王**",
            phone: "166*****276",
            desc: "已开启追剧模式",
          },
          {
            time: "5分钟前",
            name: "卢**",
            phone: "130*****639",
            desc: "已开启追剧模式",
          },
        ],
        scrollOffset: 0,
        scrollSpeed: 0.3, // 调整滚动速度
        offsetIndex: 0,
        offsetSum: 0,
        offsetFlag: false,
        showCode: false,
        phoneNumber: "",
        orderPhoneNumber: "",
        verifyCode: "",
        getCodeFlag: false,
        countdown: "",
        channelCode: "",
        videoSrc: "",
        videoDesc: "",
        videoImgSrc: "",
        player: null,
        controls: true,
      };
    },
    methods: {
      colseVideo() {
        this.showVideo = false;
      },
      videoClick(videoImg, videoSrc, videoDesc) {
        console.log(videoImg, videoSrc, videoDesc);
        this.videoSrc = videoSrc;
        this.videoDesc = videoDesc;
        this.videoImgSrc = videoImg;
        this.showVideo = true;
      },
      showYsxy() {
        window.location.href = "https://m.10155.com/h5/mactivity/#/privacy";
      },
      showYhxy() {
        window.location.href =
          "https://m.10155.com/h5/mactivity/index.html#/service";
      },
      showMoreVideo() {
        window.location.href =
          "https://m.10155.com/h5/mactivity/index.html#/openmp?path=subpages%2FshakeList%2FshakeList&channelId=3000016027";
      },
      ruleClick() {
        this.showRule = true;
      },
      closeRule() {
        this.showRule = false;
      },
      openCode() {
        this.showCode = true;
      },
      closeCode() {
        this.showCode = false;
      },
      raidoClick() {
        const hasChecked = this.hasChecked;
        this.hasChecked = !hasChecked;
      },
      checkPhoneNumber(phoneNumber) {
        var reg = /0?(13|14|15|18|17)[0-9]{9}/;
        return reg.test(phoneNumber);
      },
      order() {
        let orderPhoneNumber = this.orderPhoneNumber;
        if (!this.hasChecked) {
          this.$message("您还没勾选同意《隐私协议》和《用户协议》");
          return;
        }
        if (!orderPhoneNumber) {
          this.$message("手机号不能为空！");
          return;
        }
        if (!this.checkPhoneNumber(orderPhoneNumber)) {
          this.$message("您的手机号输入有误！");
          return;
        }
        let params = {
          phoneNumber: orderPhoneNumber,
          channelCode: this.channelCode,
          redirectUrl: "/womusic/successnew?channelCode=" + this.channelCode,
        };
        this.$api.onePointProductMon(params).then((res) => {
          console.log(res);
          if (res.success) {
            window.location.href = res.url;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      updateScrollOffset() {
        this.scrollOffset -= this.scrollSpeed;
        this.offsetSum += this.scrollSpeed;
        this.offsetIndex = this.offsetIndex + 1;
        if (this.offsetIndex >= this.historyList.length) {
          this.offsetIndex = 0;
          this.$el.querySelectorAll(".van-swipe-item").forEach((item) => {
            item.style.transform = `translateY(${this.offsetSum}rem)`;
          });
        }
      },
      getCode() {
        let phone = this.phoneNumber;
        if (!phone) {
          this.$message("手机号不能为空！");
          return;
        }
        if (!this.checkPhoneNumber(phone)) {
          this.$message("您的手机号输入有误！");
          return;
        }
        this.sendLoginCode(phone);
      },
      sendLoginCode(phone) {
        let params = {
          phoneNumber: phone,
        };
        this.$api.sendLoginCode(params).then((res) => {
          console.log(res);
          if (res.success) {
            this.startCountdown();
            this.getCodeFlag = true;
          } else {
            this.$message.error(res.message);
          }
        });
      },
      startCountdown() {
        let seconds = 60; // 设置倒计时的秒数
  
        const timer = setInterval(() => {
          if (seconds > 0) {
            this.countdown = `倒计时${seconds}s`; // 更新显示的倒计时文本
            seconds--; // 每次递减1秒
          } else {
            clearInterval(timer); // 当倒计时结束时清除定时器
            this.countdown = ""; // 可选：将倒计时文本重置为特定内容
            this.getCodeFlag = false;
          }
        }, 1000); // 每隔1秒执行一次
      },
      search() {
        if (!this.phoneNumber) {
          this.$message("请先输入手机号！");
          return;
        }
        if (!this.checkPhoneNumber(this.phoneNumber)) {
          this.$message("您的手机号输入有误！");
          return;
        }
        //if (!this.verifyCode) {
        //  this.$message("请先输入验证码！");
        //  return;
        //}
        let params = {
          phoneNumber: this.phoneNumber,
          verifyCode: this.verifyCode,
        };
        this.$api.codeLogin(params).then((res) => {
          console.log(res);
          if (res.success) {
            if (res.hasOrdered) {
              this.$message("您已订购该产品");
            } else {
              this.$message("您还未订购该产品");
            }
          } else {
            this.$message.error(res.message);
          }
        });
      },
    },
    //初始化播放器
    mounted() {
      let options = {
        autoplay: true, // 自动播放
        language: "zh-CN",
        controls: this.controls, // 控制条
        preload: "auto", // 自动加载
        errorDisplay: true, // 错误展示
        // fluid: true, // 跟随外层容器变化大小，跟随的是外层宽度
        // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但是存在
        // textTrackDisplay: false,  // 不渲染字幕相关DOM
        userActions: {
          hotkeys: true, // 是否支持热键
        },
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        techOrder: ["h5", "flash"], // 定义Video.js技术首选的顺序
        sources: [
          {
            src: "https://listen.10155.com/listener/womusic-bucket/90115000/mv_vod/volte_mp4/20240108/1744266343940870145.mp4?user=N/A&channelid=3000006627&contentid=70664000202401085001680&id=05EA3F77CC01DB932AE94E579AF9137F&timestamp=1710228572&isSegment=0", // 视频或者直播地址
            type: "video/mp4",
            //type: 'rtmp/flv',
          },
        ],
      };
  
      this.player = videojs(
        this.$refs.videoNode,
  
        options,
  
        function onPlayerReady() {
          videojs.log(`Your player is ready!`);
        }
      );
    },
  
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    created() {
      this.channelCode = this.$route.query.cmcode;
      //console.log(this.channelCode)
    },
  };
  </script>
  
  <style scoped>
  @import "../../assets/css/womusic.css";
  </style>