const config = {
  
}

if (process.env.VUE_APP_ENVIRONMENT == 'offline') {
  //本地运行环境
  config.server = "http://127.0.0.1:8989";
} else if (process.env.VUE_APP_ENVIRONMENT == 'develop') {
  //线上测试环境
  config.server = "https://sp0.jsbc0505.com";
} else if (process.env.VUE_APP_ENVIRONMENT == 'master') {
  //线上正式环境
  config.server = "https://sp0.jsbc0505.com";
}

export default config
