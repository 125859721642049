import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/api/api"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    authorities: (localStorage.getItem('authorities') != "undefined" && localStorage.getItem('authorities') != "[]") ? JSON.parse(localStorage.getItem('authorities')) : [],
    // 菜单
    menuTree: [],
    loading: false,
    loadingConfig: {
      lock: true,
      text: '正在加载...',
      background: 'hsla(0,0%,100%,.9)',
      target: '.sub-main',
      body: true,
      customClass: 'mask'
    },
    // 选择日期范围
    dateRangePickerOptions: {
      shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ]
    },
    // 选择日期
    datePickerOptions: {
      shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        },
        {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }
      ]
    },
    // 分页控件的每页显示条目个数
    paginationPageSizes: [5, 10, 15, 20, 50, 100],
    // 分页控件组件布局，子组件名用逗号分隔
    paginationLayout: 'prev, pager, next, total, sizes, jumper',
    //七牛云视频截取封面字符串
    qiniuVideoConfig: 'vframe/jpg/offset/1'
  },
  mutations: {
    setToken(state, newToken) {
      localStorage.setItem('token', newToken)
      state.token = newToken
    },
    setRefreshToken(state, newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken)
      state.refreshToken = newRefreshToken
    },
    setUserInfo(state, userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      state.userInfo = userInfo
    },
    setAuthorities(state, authorities) {
      localStorage.setItem('authorities', JSON.stringify(authorities))
      state.authorities = authorities
    },
    setMenusTree(state, menuTree) {
      state.menuTree = menuTree
    }
  },
  actions: {
    // 重新从服务器读取用户信息
    reloadUserAuthority(commit) {
      return api.listByUserSysModule({userId: store.state.userInfo.id}).then(res => {
        if(res.success){
          commit.commit("setMenusTree", res.data);
        }
      })
    },
    // 登出 清除cookie和vuex中token
    LogOut({ dispatch}) {
      return dispatch('FedLogOut');
    },
    // 前端 登出
    FedLogOut(commit) {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('authorities')
        commit.commit('setToken', '')
        commit.commit('setRefreshToken', '')
        commit.commit('setUserInfo', {})
        commit.commit('setAuthorities', [])
        commit.commit('setMenusTree', [])
    }
  }
})

export default store