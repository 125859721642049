const BasicLayout = () => import('@/layout/BasicLayout.vue')
const basicRoute = [{
  path: '',
  component: BasicLayout,
  children: [
    
  ]
}]

export {
  basicRoute
}